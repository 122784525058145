<template>
  <v-app>
    <div v-if="dataJson">
      <div class="row">
        <div class="col-xl-12">
          <v-card
            class="mx-auto rounded-lg ecosistema-ve"
            elevation="10"
            height="100%"
            outlined
          >
            <v-card-title>
              <v-avatar size="56">
                <img
                  alt="Nivel de electrificación"
                  src="/media/cide/ecosistemas/ELC.png"
                />
              </v-avatar>
              <h1 class="ml-3 texto-ecosistema-elc">Electrificación</h1>
            </v-card-title>
            <v-card-text>
              <div class="row">
                <div class="col-xl-8" />
                <div class="col-xl-2">Nacional</div>
                <div class="col-xl-2">Rural</div>
              </div>

              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Grado de electrificación en 2020
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[0].nacional }}
                  </span>
                  kWh anuales/suministro
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[0].rural }}
                  </span>
                  kWh anuales/suministro
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Grado de electrificación en 2021
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[1].nacional }}
                  </span>
                  kWh anuales/suministro
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[1].rural }}
                  </span>
                  kWh anuales/suministro
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Grado de electrificación en 2022
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2].nacional }}
                  </span>
                  kWh anuales/suministro
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2].rural }}
                  </span>
                  kWh anuales/suministro
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>Grado de electrificación</v-card-title>
            <v-card-subtitle> kWh anuales/suministro </v-card-subtitle>
            <v-card-text>
              <fusioncharts
                type="spain"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="dsMapaINP01"
              />
            </v-card-text>
          </v-card>
        </div>

        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title> Grado de electrificación en hogares </v-card-title>

            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="'/data/ELC/nacional/inp01.json'"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row" />

      <v-row>
        <v-col cols="12" sm="6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Porcentaje de usuarios con intención de aplicar medidas de
              eficiencia energética en sus hogares
            </v-card-title>

            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="400"
                data-format="jsonurl"
                :data-source="dsNacionalINP05"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Usos eléctricos más comunes en los hogares rurales
            </v-card-title>

            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="400"
                data-format="jsonurl"
                :data-source="dsNacionalINP06"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      selectedVariable:
        "Contribución a la descarbonización del Vehículo Eléctrico",
      selectedYear: "2020",
      selectedOption: "Todos",
      chartColumn: {
        type: "column2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      chartMultipleColumn: {
        type: "mscolumn2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      mapSpain: {
        type: "spain",
        width: "100%",
        height: "300",
        dataFormat: "jsonurl",
      },

      dsMapaINP01: "./data/ELC/nacional/mapa_inp01.json",
      dsNacionalINP01: "./data/ELC/nacional/inp01.json",
      // dsNacionalINP07: "./data/ELC/nacional_old/inp07.json",
      dsNacionalINP05: "./data/ELC/nacional/inp05.json",
      dsNacionalINP06: "./data/ELC/nacional/inp06.json",
      // dsantes2INP05: "./data/ELC/nacional_old/mapa_antes2_inp05.json",
      // dsentre2y5INP05: "./data/ELC/nacional_old/mapa_entre2y5_inp05.json",
      dataJson: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getJsonData();
  },
  methods: {
    getJsonData() {
      fetch("/data/ELC/nacional/data.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson = data;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
